import React from 'react';
import ReactDOM from 'react-dom';

//Ant Design Elements
import AutoComplete from "antd/lib/auto-complete";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";

import config from '../../../config';
import api from '../../../helpers/api';
import history from '../../../helpers/history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

let suburbLookupTimeout;
let currentSuburb;

class SuburbDropDown extends React.Component {

    state = {
        suburbs: [],
        autoCompleteDisabled: false,
        searchedSuburbName: null,
        renderCounter: 0
    };

    componentDidUpdate = (prevProps) => {
        if ( prevProps.pickup_address_suburb_manual_string !== this.props.pickup_address_suburb_manual_string ) {
            // Force Re-Render to pickup a parent state change
            this.setState({renderCounter: this.state.renderCounter + 1});
        }
    }

    disableAutoComplete = () => {
        if (!this.state.autoCompleteDisabled) {
            const elementsToDisable = document.querySelectorAll(".zero-autocomplete, .ant-select-search__field");
            
            for (let i = 0; i < elementsToDisable.length; i++) {
                elementsToDisable[i].setAttribute("autocomplete","any-arbitrary-text");
            }
            this.setState({ autoCompleteDisabled: true });
        }
    };

    handleSuburbSearch = value => {
        this.suburbLookup(value, suburbs => this.setState({ suburbs }));
    };

    suburbLookup = (value, callback) => {
        if (suburbLookupTimeout) {
            clearTimeout(suburbLookupTimeout);
            suburbLookupTimeout = null;
        }
        currentSuburb = value;

        function debouncedLookup() {
            api.post("suburbs/search", {
                body: JSON.stringify({
                    search: value
                })
            })
            .then(d => {
                if (currentSuburb === value) {
                    const data = [];
                    if (typeof(d.result) !== 'undefined' && typeof(d.result.suburbs) !== 'undefined') {
                        d.result.suburbs.forEach(r => {
                            data.push({
                                value: r.id,
                                text: r.suburb_name
                            });
                        });
                    }
                    callback(data);
                }
            });
        }
        suburbLookupTimeout = setTimeout(debouncedLookup, 300);
    }

    pickupSuburbSelection = (suburb_id, option) => {
        let params = {
            suburb_id: suburb_id
        };
        this.props.setAppStateValue('pickupAddressManualFields', false);
        api.post("webBookings/suburbById", {
            body: JSON.stringify(params)
        })
        .then(data => {
            if (typeof data.result === 'object' && data.result.suburb) {
                this.props.setAppStateValue('pickup_address_suburb_id', data.result.suburb.id, true, () => {
                    this.props.setAppStateValue('pickup_address_suburb_name', data.result.suburb.suburb_name, true, () => {
                        this.props.setAppStateValue('pickup_address_suburb_manual_string', option.props.children);
                        this.props.setAppStateValue('pickup_address_state', data.result.suburb.states.name);
                        this.props.setAppStateValue('pickup_address_postcode', data.result.suburb.post_code);
                        this.props.setAppStateValue('pickupAddressManualFields', true);
                    });
                });
            }
        });
    };

    submitSearch = () => {
        if (document.getElementById('root') !== null) {
            history.push('/book/lesson/?suburb=' + this.props.pickup_address_suburb_id + '&vehicle_type=' + this.props.transmissionType);
        } else {
            window.top.location.href = config.siteUrl + '/book/lesson/?suburb=' + this.props.pickup_address_suburb_id + '&vehicle_type=' + this.props.transmissionType;
        }
    };

    render() {
        const suburbs = this.state.suburbs.map(d => <AutoComplete.Option key={d.value}>{d.text}</AutoComplete.Option>);
        return ReactDOM.createPortal(
            <Row>
                <Col xs={{ span: 12, offset: 6 }} sm={{ span: 12, offset: 6 }} md={{ span: 8, offset: 5 }} lg={{ span: 7, offset: 6 }} xl={{ span: 6, offset: 7 }}>
                    <AutoComplete
                        key={"autocomplete-suburb-" + this.state.renderCounter}
                        notFoundContent={null}
                        backfill={true}
                        filterOption={false}
                        onSearch={this.handleSuburbSearch}
                        onSelect={this.pickupSuburbSelection}
                        size="large"
                        onFocus={this.disableAutoComplete}
                        className="zero-autocomplete"
                        defaultValue={this.props.pickup_address_suburb_manual_string}
                        placeholder={<span><FontAwesomeIcon fixedWidth icon={faMapMarkerAlt} size="sm" /> Search Suburb</span>}
                    >
                        {suburbs}
                    </AutoComplete>
                </Col>
                <Col xs={{ span: 12, offset: 6 }} sm={{ span: 8, offset: 8 }} md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 4, offset: 0 }}>
                    <Button
                        style={{width: '100%'}}
                        type="primary"
                        size="large"
                        onClick={() => this.submitSearch()}
                        disabled={this.props.pickup_address_suburb_manual_string === null}
                    >
                        Search Times
                    </Button>
                </Col>
            </Row>,
            document.getElementById(this.props.element),
        );
    }
};

export default SuburbDropDown;
