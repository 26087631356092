import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import ReactDOM from 'react-dom';

//Ant Design Elements
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";

import api from '../../../helpers/api';

class DKTDownloadFormClass extends React.Component {

    state = {
        saving: false,
        submitButtonDisabled: true,
        formHasBeenSubmitted: false
    };

    validateForm  = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // No Errors : Disabled = False
                this.setState({
                    submitButtonDisabled: false
                });
            } else {
                // Errors : Disabled = True
                this.setState({
                    submitButtonDisabled: true
                });
            }
        });
    };

    goNextField = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            var inputs = document.querySelectorAll("#dkt_download_form input, #dkt_download_form textarea");

            var lastElement = true;
            for (var i = 0; i < inputs.length;i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute('autofocus');

                    inputs[i + 1].setAttribute('autofocus', 'autofocus');
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitForm();
            }
        }
    }

    submitForm = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return false;
            }

            this.setState({
                saving: true
            }, () => {
                if (!!this.props.user.id) {
                    values.student_id = this.props.user.id;
                }
                api.post("dktdownload", {
                    body: JSON.stringify(values)
                })
                .then(data => {
                    if (typeof data.result === 'object' && data.result.success) {
                        this.setState({
                            formHasBeenSubmitted: true,
                        });
                    } else {
                        Modal.error({
                            className: 'unavailable-survey',
                            title: 'Error!',
                            content: 'Sorry, an error occurred. Please try again!',
                            icon: false,
                            closable: false,
                            keyboard: false
                        });
                    }
                    this.setState({
                        saving: false
                    });
                })
                .catch(error => {
                    Modal.error({
                        className: 'unavailable-survey',
                        title: 'Error!',
                        content: 'Sorry, an error occurred. Please try again!',
                        icon: false,
                        closable: false,
                        keyboard: false
                    });
                    this.setState({
                        saving: false
                    });
                });
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

        let DKTDownloadFormToRender = <>
            <Form id="dkt_download_form">
                <Row>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '300px',
                        }}
                    >
                        {this.state.formHasBeenSubmitted ? (
                            <>
                                <p className="heading-md">Thank You</p>
                                <p className="paragraph-md">You will receive an email shortly with instant access to the Driver Knowledge Test Guide.</p>
                            </>
                        ) : (
                            <>
                                <Form.Item
                                    label={false}
                                    style={{width:'100%'}}
                                >
                                    {getFieldDecorator('name', {
                                        rules: [
                                            { required: true, message: 'Enter your name.', whitespace: true },
                                            { max: 60, message: 'Name cannot be more than 60 characters.' },
                                        ],
                                        initialValue: (!!this.props.user.id ? (this.props.user.first_name + ' ' + this.props.user.last_name) : '')
                                    })(
                                        <Input
                                            maxLength={60}
                                            size="large"
                                            onKeyDown={this.goNextField}
                                            enterKeyHint="next"
                                            placeholder="Your Name"
                                            onKeyUp={this.validateForm}
                                            disabled={this.state.saving}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label={false}
                                    style={{width:'100%'}}
                                >
                                    {getFieldDecorator('email', {
                                        rules: [
                                            { required: true, type: 'email', message: 'Enter a valid Email.', whitespace: true },
                                            { max: 60, message: 'Email cannot be more than 60 characters.' },
                                        ],
                                        initialValue: (!!this.props.user.id ? (this.props.user.email) : '')
                                    })(
                                        <Input
                                            type="email"
                                            maxLength={60}
                                            size="large"
                                            onKeyDown={this.goNextField}
                                            enterKeyHint="next"
                                            placeholder="Email Address"
                                            onKeyUp={this.validateForm}
                                            disabled={this.state.saving}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label={false}
                                    style={{width:'100%'}}
                                >
                                    {getFieldDecorator('date', {
                                        rules: [
                                            { type: 'object', required: true, message: 'Enter your DKT Date.', whitespace: true }
                                        ]
                                    })(
                                        <DatePicker
                                            inputReadOnly={true}
                                            format={dateFormatList}
                                            popupStyle={{
                                                zIndex: "99999"
                                            }}
                                            showToday={false}
                                            disabled={this.state.saving}
                                            disabledDate={(current) => {
                                                // Future dates only
                                                return current < moment();
                                            }}
                                            size="large"
                                            style={{width:'100%'}}
                                            onBlur={this.validateForm}
                                            placeholder="Date of DKT"
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    style={{width:'100%'}}
                                >
                                   <Button
                                        style={{width: '100%'}}
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        onClick={() => this.submitForm()}
                                        disabled={this.state.submitButtonDisabled || this.state.saving}
                                        loading={this.state.saving}
                                    >
                                        Download
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Col>
                    <Col
                        span={12}
                        style={{
                            textAlign:'center'
                        }}
                    >
                        <img
                            loading="lazy"
                            src={process.env.REACT_APP_SITE_URL + "/img/dkt.webp"}
                            alt="DKT Download"
                            width="212"
                            height="300"
                       />
                    </Col>
                 </Row>
            </Form>
        </>;

        if (this.props.embed) {
            return ReactDOM.createPortal(
                DKTDownloadFormToRender,
                document.getElementById('react-dkt-download-form'),
            );
        }

        return DKTDownloadFormToRender;
    }
};

const DKTDownloadForm = Form.create({name: 'dkt_download_form'})(DKTDownloadFormClass);
export default withRouter(DKTDownloadForm);
