const base64Decode = (str) => {
    return decodeURIComponent(
        atob(str).replace(/(.)/g, (_m, c) => {
            let code = c.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                code = "0" + code;
            }
            return "%" + code;
        })
    );
};

const base64UrlDecode = (str) => {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += "==";
            break;
        case 3:
            output += "=";
            break;
        default:
            console.log('no good here');
            throw new Error("Illegal Base64URL string!");
    }

    try {
        return base64Decode(output);
    } catch (err) {
        return atob(output);
    }
};

const decode = (token, { header = false } = {}) => {
    const pos = header === true ? 0 : 1;
    try {
        return JSON.parse(base64UrlDecode(token.split(".")[pos]));
    } catch (err) {
        return {};
    }
};

export default decode;
