import React from 'react';
import ReactDOM from 'react-dom';
import config from '../../../config';
import LoginModal from '../../elements/Students/LoginModal';

class LoginLogout extends React.Component {

    render() {
        return ReactDOM.createPortal(
            <React.Fragment>
                {((!this.props.user.id && this.props.newGoogleUser === null && this.props.newFacebookUser === null) || this.props.shell_student) && (
                    <div className="already-student-link">
                        <span onClick={() => this.props.loginModalToggle('login')}>Log In</span>
                    </div>
                )}
                {!((!this.props.user.id && this.props.newGoogleUser === null && this.props.newFacebookUser === null) || this.props.shell_student) && (
                    <a
                        href={config.siteUrl + "/student-profile/"}
                        style={{
                            border: '2px solid #888',
                            padding: '0px 20px',
                            borderRadius: '10px',
                            color: '#888',
                            fontSize: '14pt',
                            fontWeight: '300',
                            lineHeight: '35px',
                        }}
                    >My Profile</a>
                )}
                {(!this.props.user.id || this.props.shell_student) && this.props.loginModalVisible && (
                    <LoginModal
                        {...this.props}
                    />
                )}
            </React.Fragment>,
            document.getElementById(this.props.element),
        );
    }
};

export default LoginLogout;
