import React from 'react';
import { withRouter } from 'react-router';
import ReactDOM from 'react-dom';

//Ant Design Elements
import Button from "antd/lib/button";
import Drawer from "antd/lib/drawer";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";

import api from '../../../helpers/api';
import config from '../../../config';

class NewsletterFormClass extends React.Component {

    state = {
        saving: false,
        submitButtonDisabled: true,
        drawerVisible: false,
        // Once set to True, the Drawer cannot be re-opened (stops it being annoying)
        drawerHasBeenClosed: false,
    };

    componentDidMount = () => {
        // After 5 minutes on a page, open the Drawer
        setTimeout(() => {
            this.setState({
                drawerVisible: true
            });
        }, 300000)

        // Start the Exit Intent Trigger to show Modal when pointer exits the top of the window
        this.applyExitIntentTrigger(() => this.setState({
            drawerVisible: true
        }));
    };

    /**
     * This function was copied from EmailOctopus' newsletter subscription modal
     */
    applyExitIntentTrigger = (exitIntentCallback) => {
        // How close to the top of the screen (in pixels) the mouse should move to,
        // before we trigger the modal.
        const sensitivity = 20;

        // How long before we activate the exit intent logic in ms.
        // We may want the page to have been loaded for a certain amount of time
        // before showing any modals.
        const timer = 3_000;

        // The delay between the mouse leaving the screen and the modal being
        // shown. If the mouse re-enters the screen during the delay, the display
        // of the modal is cancelled.
        const MODAL_DISPLAY_DELAY = 500;

        // The timeout ID for the current (if any) setTimeout call to show the
        // modal. Can be used to cancel the display of the modal.
        let modalTimeoutId = null;

        setTimeout(attachEventListeners, timer);

        function attachEventListeners () {
            document.documentElement
                .addEventListener('mouseleave', onMouseleave);

            document.documentElement
                .addEventListener('mouseenter', onMouseenter);
        }

        /**
         * If the mouse leaves the screen via the top of the screen (as if the user
         * is getting ready to close the current tab), we queue the display of the
         * modal after the `MODAL_DISPLAY_DELAY`.
         */
        function onMouseleave (event) {
            if (event.clientY > sensitivity) {
                return;
            }

            modalTimeoutId = setTimeout(showModal, MODAL_DISPLAY_DELAY);
        }

        /**
         * Cancel the display of the modal, if the mouse moves back into the
         * screen sooner than `MODAL_DISPLAY_DELAY`.
         */
        function onMouseenter () {
            if (modalTimeoutId) {
                clearTimeout(modalTimeoutId);
                modalTimeoutId = null;
            }
        }

        function showModal () {
            exitIntentCallback();

            removeEventListeners();
        }

        function removeEventListeners () {
            document.documentElement.removeEventListener(
                'mouseleave',
                onMouseleave
            );

            document.documentElement.removeEventListener(
                'mouseenter',
                onMouseenter
            );
        }
    }

    validateForm  = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // No Errors : Disabled = False
                this.setState({
                    submitButtonDisabled: false
                });
            } else {
                // Errors : Disabled = True
                this.setState({
                    submitButtonDisabled: true
                });
            }
        });
    };

    goNextField = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let inputs = document.querySelectorAll("#newsletter_form input, #newsletter_form textarea");

            let lastElement = true;
            for (let i = 0; i < inputs.length;i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute('autofocus');

                    inputs[i + 1].setAttribute('autofocus', 'autofocus');
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitForm();
            }
        }
    }

    submitForm = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return false;
            }

            this.setState({
                saving: true
            }, () => {
                if (!!this.props.user.id) {
                    values.student_id = this.props.user.id;
                }
                api.post("newsletter", {
                    body: JSON.stringify(values)
                })
                .then(data => {
                    if (typeof data.result === 'object' && data.result.success) {
                        Modal.success({
                            className: 'unavailable-survey',
                            title: "Thank You. You're now subscribed.",
                            content: <p>Make sure to check your email regularly for your exclusive content.</p>,
                            icon: false,
                            closable: false,
                            keyboard: false,
                            onOk: () => {
                                this.setState({
                                    drawerVisible: false,
                                    drawerHasBeenClosed: true,
                                });
                            }
                        });
                    } else {
                        Modal.error({
                            className: 'unavailable-survey',
                            title: 'Error!',
                            content: 'Sorry, an error occurred. Please try again!',
                            icon: false,
                            closable: false,
                            keyboard: false
                        });
                    }
                    this.setState({
                        saving: false
                    });
                })
                .catch(error => {
                    Modal.error({
                        className: 'unavailable-survey',
                        title: 'Error!',
                        content: 'Sorry, an error occurred. Please try again!',
                        icon: false,
                        closable: false,
                        keyboard: false
                    });
                    this.setState({
                        saving: false
                    });
                });
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let NewsletterFormToRender = <>

            <Drawer
                title={false}
                placement={'bottom'}
                closable={true}
                onClose={() => {
                    this.setState({
                        drawerVisible: false,
                        drawerHasBeenClosed: true,
                    });
                }}
                visible={this.state.drawerVisible && !this.state.drawerHasBeenClosed}
                className='newsletter-drawer'
            >
                <Form id="newsletter_form">
                    <p
                        className="paragraph-lg"
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <img
                            src={config.siteUrl + "/img/logo-ltrent.webp"}
                            height="35"
                            width="225"
                            alt={config.drivingSchoolMediumName + " Logo"}
                        />
                    </p>
                    <p
                        className="paragraph-sm"
                        style={{
                            marginBottom: 0,
                            textAlign: 'center',
                        }}
                    >
                        Get your <u>FREE guide on best locations</u> to learn to drive and more! Sign up to our newsletter.
                    </p>

                    <Form.Item
                        label={false}
                    >
                        {getFieldDecorator('email', {
                            rules: [
                                { required: true, type: 'email', message: 'Enter a valid Email.', whitespace: true },
                                { max: 60, message: 'Email cannot be more than 60 characters.' },
                            ],
                            initialValue: (!!this.props.user.id ? (this.props.user.email) : '')
                        })(
                            <Input
                                type="email"
                                maxLength={60}
                                size="large"
                                onKeyDown={this.goNextField}
                                enterKeyHint="next"
                                placeholder="Email Address"
                                onKeyUp={this.validateForm}
                                disabled={this.state.saving}
                                style={{
                                    marginTop: '5px'
                                }}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                       <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            onClick={() => this.submitForm()}
                            disabled={this.state.submitButtonDisabled || this.state.saving}
                            loading={this.state.saving}
                            style={{
                                width: '100%',
                                marginTop: '1em',
                            }}
                        >
                            Subscribe
                        </Button>
                     </Form.Item>
                </Form>
            </Drawer>
        </>;

        if (this.props.embed) {
            return ReactDOM.createPortal(
                NewsletterFormToRender,
                document.getElementById('react-newsletter-form'),
            );
        }

        return NewsletterFormToRender;
    }
};

const NewsletterForm = Form.create({name: 'newsletter_form'})(NewsletterFormClass);
export default withRouter(NewsletterForm);
