import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import * as Sentry from "@sentry/react";

if (config.environment === 'production') {
    Sentry.init({
      dsn: 'https://6cc06a27464449ce871a44c988699afa@sentry.twopicode.com/73'
    });
}

if (document.getElementById('react-main') !== null) {
    ReactDOM.render(
                <App />,
            document.getElementById('react-main')
    );
}
